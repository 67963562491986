.play {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40vw;
}

.form input {
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 1rem;
    padding: 0 20px 0 20px;
    margin-right: 10px;
    font-size: 20px;
}

.form button {
    height: 50px;
    border: none;
    border-radius: 1rem;
    padding: 0 20px 0 20px;
    background-color: #f5f5f5;
    cursor: pointer;
    font-size: 20px;
}

.form button:hover {
    background-color: #e5e5e5;
}

.form button:active {
    background-color: #d5d5d5;
}

.error {
    color: rgb(254, 123, 123);
    font-weight: bold;
    min-height: 1.2em;
}

.giveUp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 150px;
    cursor: pointer;
    margin-left: 10px;
    color: #E3BCCF;
}

.giveUp:active{
    color: blue;
}

.giveUp p{
    margin: 0;
    padding: 0;
}

.loadingDiv {
    width: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingImg {
    object-fit: cover;
    overflow: hidden;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}