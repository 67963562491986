.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #565461;
    width: 70vw;
    margin-top: 30px;
    padding: 20px;
    border-radius: 10px;
}

.home h4 {
    width: 30%;
}

.login {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 25vw;
    padding: 5px;
    border-bottom: 2px solid black;
}

.start {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 25vw;
    padding: 5px;

}

.parameters {
    display: flex;
    flex-direction: column;
}

.parameters select {
    height: 50px;
    width: 175px;
    padding: 5px;
    margin-bottom: 5px;
    background-color: #E3BCCF;
    border-radius: 1rem;
    font-size: 20px;
}

.parameters select:hover {
    background-color: rgb(158, 158, 158);

}

.startButton {
    background-color: #E3BCCF;
    outline: none;
    border: none;
    width: 100px;
    height: 50px;
    font-size: 20px;
    border-radius: 1rem;
}

.startButton:hover {
    background-color: rgb(158, 158, 158);
}

.startButton:active {
    background-color: rgb(111, 111, 111);
}   