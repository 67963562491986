.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E3BCCF;
    width: 100vw;
    height: 60px;
}

.header h1 {
    cursor: pointer;
    margin: 0 0 0 20px;
    padding: 0;
    color: #E3BCCF;
}

.header h2 {
    color: #E3BCCF;

}

.login {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin-right: 20px;
}

.user {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #E3BCCF;
    padding: 5px;
    border-radius: 1rem;
    width: 125px;
    font-size: 20px;
    margin-right: 10px;
}
.user p {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
}

.user img {
    border-radius: 1rem;
    height: 40px;
    border: 1px solid black;
}

.loginButton {
    background-color: #E3BCCF;
    outline: none;
    border: none;
    width: 100px;
    height: 50px;
    font-size: 20px;
    border-radius: 1rem;
}

.loginButton:hover {
    background-color: rgb(158, 158, 158);
}

.loginButton:active {
    background-color: rgb(111, 111, 111);
} 