.guessCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 60vw;
    border-radius: 1rem;
    background-color: #E3BCCF;
    padding: 0 20px 0 20px;
    margin-bottom: 10px;
}

.labelCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 60vw;
    color: #E3BCCF;
    border-bottom: 2px solid #E3BCCF;
    padding: 0 20px 0 20px;
    margin-bottom: 10px;
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    width: 70%;
    height: 40px;
}

.contentItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.box h3 {
    margin: 0;
}

.box h1 {
    margin: 0;
}

.content h3 {
    margin: 0;

}

.content h4 {
    margin: 0;
}

.green {
    background-color: rgb(91, 176, 91);
}
.red {
    background-color: rgb(252, 138, 138);
}

@keyframes pop {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.pop {
    animation: pop 0.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeIn {
    animation: fadeIn 1s;
}